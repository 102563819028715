var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"o-admin-dashboard"},[_c('div',{staticClass:"data__basic"},[_c('div',{staticClass:"data__minimal"},[_c('div',{staticClass:"data__minimal--text"},[_c('p',[_vm._v(_vm._s(`${_vm.getGymPopulation} athletes`))]),_c('span',[_vm._v("Activity")])]),_vm._m(0)]),_c('div',{staticClass:"data__minimal"},[_c('div',{staticClass:"data__minimal--text"},[_c('p',[_vm._v(_vm._s(`${_vm.getOrdersCounter} pending`))]),_c('span',[_vm._v("Orders")])]),_vm._m(1)]),_c('div',{staticClass:"data__minimal"},[_c('div',{staticClass:"data__minimal--text"},[_c('p',[_vm._v(_vm._s(`${_vm.getEmployeesCounter} employees`))]),_c('span',[_vm._v("Staff")])]),_vm._m(2)])]),_c('div',{staticClass:"data__basic"},[_c('div',{staticClass:"data__basic--container"},[_c('apexchart',{key:_vm.dataLoader,attrs:{"type":"area","height":"250","options":_vm.chart1Options,"series":_vm.athletesAmount}})],1),_c('div',{staticClass:"data__basic--container"},[_c('apexchart',{key:_vm.dataLoader,attrs:{"type":"area","height":"250","options":_vm.chart2Options,"series":_vm.averageTime}})],1)]),_c('div',{staticClass:"data__basic--container data__basic--container-full-width"},[_vm._m(3),_c('div',{staticClass:"weather__header"},[_c('div',{staticClass:"weather__item"},[_vm._m(4),_c('div',{staticClass:"weather__info-text"},[_c('p',[_vm._v(_vm._s(_vm.currentTemp(_vm.getWeather)))]),_c('span',[_vm._v("Temp")])])]),_c('div',{staticClass:"weather__item"},[_vm._m(5),_c('div',{staticClass:"weather__info-text"},[_c('p',[_vm._v(_vm._s(_vm.currentRain(_vm.getWeather)))]),_c('span',[_vm._v("Rain")])])]),_c('div',{staticClass:"weather__item"},[_vm._m(6),_c('div',{staticClass:"weather__info-text"},[_c('p',[_vm._v(_vm._s(_vm.currentWind(_vm.getWeather)))]),_c('span',[_vm._v("Wind")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data__minimal--icon"},[_c('img',{attrs:{"src":require("@/assets/icons/gym.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data__minimal--icon"},[_c('img',{attrs:{"src":require("@/assets/icons/file.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data__minimal--icon"},[_c('img',{attrs:{"src":require("@/assets/icons/group.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weather__header"},[_c('div',{staticClass:"weather__container"},[_c('h4',[_vm._v("Weather")]),_c('div',{staticClass:"location"},[_c('div',{staticClass:"location__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/pin.png")}})]),_c('p',[_vm._v("Galati")])])]),_c('div',{staticClass:"weather__container"},[_c('div',{staticClass:"location__symbol"},[_c('img',{attrs:{"src":require("@/assets/icons/cloudy.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weather__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/thermometer.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weather__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/umbrella.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"weather__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/wind-energy.png")}})])
}]

export { render, staticRenderFns }